import { template as template_7138d0e573474bcba61aee6322fad5f5 } from "@ember/template-compiler";
const FKLabel = template_7138d0e573474bcba61aee6322fad5f5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
